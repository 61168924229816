import cx from 'classnames'
import React from 'react'
import { ChoiceProps } from './types'

export const Choice = ({
  actions,
  choice,
  correct,
  incorrect,
  index,
  selected,
}: ChoiceProps): JSX.Element => {
  return (
    <div
      className={cx('quiz-answer-container', { selected, correct, incorrect })}
    >
      {selected && (
        <span aria-live="polite">
          {correct && 'correct'}
          {incorrect && 'incorrect'}
        </span>
      )}
      <button
        className="quiz-answer"
        onClick={() => actions.selectChoice(index)}
        aria-pressed={selected}
      >
        <span
          className="quiz-answer-text"
          dangerouslySetInnerHTML={{ __html: choice }}
        />
      </button>
      <div className="quiz-answer-icon">
        <i className="quiz-answer-icon-correct fa fa-check" />
        <i className="quiz-answer-icon-incorrect fa fa-times" />
        <i className="quiz-answer-icon-missed fa fa-circle-o" />
      </div>
    </div>
  )
}
