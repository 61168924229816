import DOMPurify from 'dompurify'
;(window as any).DOMPurify = DOMPurify

import '../shared/imageloader'
import '../shared/pdf-viewer'
import '../shared/polyfills'
import '../shared/util/publicPath'
import '../shared/x-origin-download'
import './lectures'
import './lectures/audioloader'
import './lectures/code-viewer.ts'
import './lectures/controls'
import './lectures/dynamic-page-initializer'
import './util/analytics-util'
