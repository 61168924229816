import { iris } from '@teachable/iris'
import { emit } from 'src/util/iris-emit-common'

// This object will map any data attributes to their keys for event emission.
const STUDENT_SIDE_DATA_ATTRS = {
  'data-ss-event-name': 'name',
  'data-ss-event-href': 'href',
  'data-ss-event-type': 'type',
  'data-ss-course-id': 'courseId',
  'data-ss-lecture-id': 'lectureId',
  'data-ss-quiz-id': 'quizId',
  'data-ss-school-id': 'schoolId',
  'data-ss-user-id': 'userId',
}

const initAnalytics = () => {
  // Initialize Iris library for student side tracking events.
  try {
    const irisUrlTag = document.getElementById('iris-url')
    const irisUrl =
      (irisUrlTag && irisUrlTag.getAttribute('data-iris-url')) || ''

    iris.init({
      source: 'fedora-student',
      url: irisUrl,
      noun: 'user',
      noun_id: '',
      selector: '',
    })
  } catch {
    console.error('Iris is not supported')
  }
}

export const emitStudentSideEvent = element => {
  const metaData = {}

  // Loop through attribute map and build metadata based on what's available
  // on the element being targeted in the DOM.
  Object.entries(STUDENT_SIDE_DATA_ATTRS).forEach(([k, v]) => {
    const dataAttribute = element.getAttribute(k)

    if (dataAttribute) {
      metaData[v] = dataAttribute
    }
  })

  emit('clicked', metaData)
}

const addEventListeners = () => {
  const trackableElements = document.querySelectorAll('[data-ss-event-name]')

  for (const element of Array.from(trackableElements)) {
    element.addEventListener('click', e => {
      emitStudentSideEvent(element)
    })
  }
}

document.addEventListener('DOMContentLoaded', () => {
  initAnalytics()
  addEventListeners()
})
