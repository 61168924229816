import onReady from 'student/shared/util/on-ready'

const ATTRIBUTE = 'data-x-origin-download'
const ATTRIBUTE_INITIALIZED = 'data-x-origin-download-initialzed'
const ATTRIBUTE_NAME = 'data-x-origin-download-name'
const SELECTOR = `[${ATTRIBUTE}]`

function forceDownload(url, fileName, callback) {
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      const imageUrl = URL.createObjectURL(blob)
      const tag = document.createElement('a')

      tag.href = imageUrl
      tag.download = fileName
      document.body.appendChild(tag)
      tag.click()
      document.body.removeChild(tag)

      callback()
    })
}

function handleClick(e) {
  const href = e.target.getAttribute('href')
  const name = e.target.getAttribute(ATTRIBUTE_NAME)

  // Mmake sure we've got the necessary data before proceeding.
  // The URL is obvious. If "name" is missing the user's
  // OS won't know which application to use to open the file.
  if (!href || !name || e.target.classList.contains('is-fetching')) {
    return
  }

  e.preventDefault()

  e.target.classList.add('is-fetching')

  forceDownload(href, name, () => {
    e.target.classList.remove('is-fetching')
  })
}

function initializeElementBehavior($el) {
  if ($el.hasAttribute(ATTRIBUTE_INITIALIZED)) {
    return
  }

  $el.setAttribute(ATTRIBUTE_INITIALIZED, true)

  $el.addEventListener('click', handleClick)
}

function initializeXOriginaDownloads() {
  // Feature detection. This is a really well supported API but
  // it's trivial to fallback gracefully to native behavior in old
  // browsers so let's do that here before we do any other work.
  if (!window.URL) {
    return
  }

  const $els = document.querySelectorAll(SELECTOR)

  for (let i = 0; i < $els.length; i += 1) {
    initializeElementBehavior($els[i])
  }
}

onReady(initializeXOriginaDownloads)
