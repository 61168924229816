import api from './api'

export async function getComplianceData(courseId: string, lectureId: string) {
  return await api(
    `/courses/${courseId}/lectures/${lectureId}/completion_data`,
    {
      method: 'GET',
    }
  )
}
