import getDataAttributes from 'student/shared/util/get-data-attributes'
import { ATTACHMENT_SELECTOR, VIDEO_ATTACHMENT_SELECTOR } from './constants'
import { LectureVideos } from './LectureVideos'

export function initVideos(
  lectureCompletionData: any,
  lectureCompletionDataEl: HTMLElement
) {
  const fedoraMeta = document.getElementById('fedora-data')
  const fedoraData = fedoraMeta && getDataAttributes(fedoraMeta)
  const playerEls: HTMLElement[] = []
  const allAttachmentEls = Array.from(
    document.querySelectorAll(ATTACHMENT_SELECTOR)
  )

  allAttachmentEls.forEach(attachmentEl => {
    const el = attachmentEl as HTMLElement

    if (attachmentEl.querySelectorAll(VIDEO_ATTACHMENT_SELECTOR).length) {
      playerEls.push(el)
    }
  })

  if (playerEls.length) {
    new LectureVideos(
      lectureCompletionData,
      playerEls,
      fedoraData,
      lectureCompletionDataEl
    )
  }
}
