// Set the webpack public path variable to the cdn host if present
//
// See: https://webpack.js.org/guides/public-path/#on-the-fly
declare var __webpack_public_path__: string

// All pages should include this meta tag
// with the host to use for assets
const ASSET_META_TAG = 'meta[name="asset_host"]'

function setWebpackPublicPath(): void {
  const $assetMeta = document.querySelector<HTMLMetaElement>(ASSET_META_TAG)
  if ($assetMeta === null) {
    throw new Error(`${ASSET_META_TAG} not found on the page`)
  }

  const assetHost = $assetMeta.content
  // guard against being run 2x
  if (__webpack_public_path__.indexOf(assetHost) === -1) {
    __webpack_public_path__ = `${assetHost}${__webpack_public_path__}`
  }
}
setWebpackPublicPath()
