export const NAMES = {
  AUTOPLAY: 'autoplay',
  AUTOCOMPLETE: 'autocomplete',
  SPEED: 'speed',
  FLASH: 'flash',
  HTML5: 'html5',
  PLAYER_PREFERENCE: 'playerPreference',
}

export const SPEED_INCREMENTER = 0.25
export const SPEED_MAX = 2
export const SPEED_MIN = 0.75

export const CONTROLS_DEFAULT = {
  [NAMES.AUTOCOMPLETE]: false,
  [NAMES.AUTOPLAY]: false,
  [NAMES.SPEED]: 1,
  [NAMES.PLAYER_PREFERENCE]: 'html5',
}

export const LOCAL_STORAGE_KEY = 'teachableControls'
