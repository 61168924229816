import React from 'react'
import { Choice } from './Choice'
import { QuestionProps } from './types'

export const Question = ({
  actions,
  currentCorrectResponse,
  isShowingError,
  question,
  selectedChoices = [],
  strings,
}: QuestionProps): JSX.Element => {
  return (
    <article className="Quiz-item" ref={actions.setHeight}>
      <div
        className="quiz-question"
        dangerouslySetInnerHTML={{ __html: question.title }}
      />
      <div className="quiz-answer-container">
        {isShowingError && (
          <div className="alert alert-warning" aria-live="polite">
            {strings.error}
          </div>
        )}
        {question.choices.map((choice, index) => (
          <Choice
            actions={actions}
            choice={choice}
            correct={Boolean(
              currentCorrectResponse && currentCorrectResponse[choice]
            )}
            index={index}
            incorrect={Boolean(
              currentCorrectResponse && !currentCorrectResponse[choice]
            )}
            key={`choice-${index}`}
            selected={selectedChoices.indexOf(choice) !== -1}
          />
        ))}
        <div className="quiz-answer-icon">
          <i className="quiz-answer-icon-correct fa fa-check" />
          <i className="quiz-answer-icon-incorrect fa fa-times" />
          <i className="quiz-answer-icon-missed fa fa-circle-o" />
        </div>
      </div>
    </article>
  )
}
