/**
 * @file
 * Puts a placeholder on top of audio files and only begins downloading them
 * when a user interacts with the embed. This is done to prevent users from
 * downloading a bunch of data on pageload.
 *
 * You should never need to interact with anything in this file, just load
 * this file and format your HTML correctly, like this:
 *
 * @example
 * <div
 *   data-audioloader
 *   data-audioloader-url="{{ url }}"
 *   data-audioloader-type="{{ type }}"
 * ></div>
 */

import onReady from 'student/shared/util/on-ready'

const ATTRIBUTE_ID = 'audioloader'
const ATTRIBUTES = {
  audioloader: `data-${ATTRIBUTE_ID}`,
  initialized: `data-${ATTRIBUTE_ID}-initialized`,
  name: `data-${ATTRIBUTE_ID}-name`,
  type: `data-${ATTRIBUTE_ID}-type`,
  url: `data-${ATTRIBUTE_ID}-url`,
}

const DEFAULT_CONFIG = {
  name: undefined, // String
  type: undefined, // String
}

const getAudioTemplate = (data) => `
  <audio class="${ATTRIBUTE_ID}__audio" controls autoplay ${data.type ? `type=${data.type}` : ''}>
    <source src=${data.url}>
    Your browser does not support the audio element
  </audio>
`

const getName = (name) => {
  if (!name) {
    return ''
  }

  return `<span class="${ATTRIBUTE_ID}__name">${name}</span>`
}

const getPlaceholderTemplate = (data) => `
  <div class="${ATTRIBUTE_ID}__placeholder">
    <button data-href="${data.url}" target="_blank" style="
      border: 0;
      outline: 0;
      background: transparent;
      cursor: pointer;
    ">
      <span class="${ATTRIBUTE_ID}__icon glyphicon glyphicon-play"></span>
      ${getName(data.name)}
    </button>
  </div>
`

class AudioLoader {
  constructor($el, url, config = {}) {
    if (!$el || !url) {
      return
    }

    this.boundHandleClick = this.handleClick.bind(this)

    this.$el = $el
    this.url = url
    this.config = {
      ...DEFAULT_CONFIG,
      ...config,
    }

    this.init()
  }

  init() {
    this.$el.innerHTML = getPlaceholderTemplate({
      name: this.config.name,
      url: this.url,
    })

    this.$el.addEventListener('click', this.boundHandleClick, {
      once: true,
    })
  }

  handleClick(e) {
    e.preventDefault()

    this.renderAudio()
  }

  renderAudio() {
    this.$el.innerHTML = getAudioTemplate({
      url: this.url,
      type: this.config.type,
    })

    this.$el.removeEventListener('click', this.boundHandleClick)
  }
}

function init() {
  const $els = document.querySelectorAll(`[${ATTRIBUTES.audioloader}]`)

  if (!$els.length) {
    return
  }

  Array.prototype.forEach.call($els, ($el) => {
    if ($el.getAttribute(ATTRIBUTES.initialized)) {
      return
    }

    $el.setAttribute(ATTRIBUTES.initialized, true)

    const url = $el.getAttribute(ATTRIBUTES.url)
    const type = $el.getAttribute(ATTRIBUTES.type)
    const name = $el.getAttribute(ATTRIBUTES.name)

    // I hate this rule, I like using classes for object creation.
    /* eslint no-new: 0 */
    new AudioLoader($el, url, {
      name,
      type,
    })
  })
}

onReady(init)
