interface DataAttributeAsObject {
  [prop: string]: any
}

export default function getDataAttributesAsObject(
  $el: Element
): DataAttributeAsObject {
  const results = {}

  const attrs = Array.prototype.filter.call($el.attributes, attr =>
    /^data-/.test(attr.name)
  )

  attrs.forEach(({ name }) => {
    let val: any = $el.getAttribute(name) || ''

    try {
      val = JSON.parse(val)
    } catch (e) {
      // Just swallow the error. This has the effect of letting the strings
      // stay strings and parsing all the other types.
    }

    const prop = name
      .replace(/data\-/, '')
      .replace(/-[a-z]/, g => g[1].toUpperCase())

    results[prop] = val
  })

  return results
}
