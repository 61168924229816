import { get } from 'lodash'
import { pub } from 'src/util/pubsub'
import api from 'student/shared/util/api'
import findAncestor from 'student/shared/util/find-ancestor'
import getDataAttributesAsObject from 'student/shared/util/get-data-attributes'
import { scrollIntoView } from 'student/shared/util/scroll'
import { getCurrentUser } from 'student/shared/util/user'
import { getControlValue, onControlChange } from '../../controls'
import { getControls } from '../../controls/storage'
import { MAX_PERCENTAGE } from '../constants'
import { WistiaType } from '../types'

const wistiaQueue = (window as any)._wq || []
const Wistia: WistiaType = (window as any).Wistia
const currentLectureId = (window as any).currentLectureId

const PLAYER_COLOR_SELECTOR = 'meta[name="brand_video_player_color"]'
const STOCK_WISTIA_THUMBNAIL =
  'https://fast.wistia.com/assets/images/zebra/elements/dashed-thumbnail.png'
const WISTIA_SELECTOR = '[data-wistia-id]'

export function getCurrentVideos(): string[] {
  const videoElements = document.querySelectorAll(WISTIA_SELECTOR)
  const elArray = Array.from(videoElements)
  const filtered: Element[] = elArray.filter(element => {
    return element.hasAttribute('data-wistia-id')
  })
  const vals: string[] = filtered.map(element => {
    return String(element.getAttribute('data-wistia-id'))
  })

  return vals
}

////////////////////////////////////////////////
// Initializers.
export function initializeWistiaVideos() {
  setGlobalCallbacks()
  setupVideoPlayers()
}

export function destroyWistiaVideos() {
  if ((window as any).removeWistiaVideos) {
    ;(window as any).removeWistiaVideos()
  }
}

// Wistia expects certain things to be present on window. This
// function handles all of that.
function setGlobalCallbacks() {
  const global = window as any

  global.wistiaInit = function(W) {
    const { email, playerColor, playerPreference } = getWistiaConfiguration()

    W.options({
      email,
      playerColor,
      playerPreference,
      stillSnap: false,
      wmode: 'transparent',
      videoFoam: {
        minWidth: 300,
        maxWidth: 1080,
      },
      googleAnalytics: false,
    })
  }

  global.removeWistiaVideos = () => {
    const videos = getCurrentVideos()

    videos.forEach(id => {
      if (id) {
        Wistia.api(id).remove()
      }
    })
  }
}

// Loops through all videos in the DOM and registers them with Wistia.
// This will later kick off the "onReady" event that initializes
// the video lifecycle logic.
function setupVideoPlayers() {
  const $videos = document.querySelectorAll(WISTIA_SELECTOR)

  Array.from($videos).forEach(register)
}

// Get some data from the DOM and use it to initialize the video.
function register($video: HTMLElement) {
  const data = getDataAttributesAsObject($video)
  const still = getStillUrl(data.stillUrl)

  if (still) {
    $video.classList.add(`stillUrl=${still}`)
  }

  // Add this class so Wistia knows where to mount the video.
  $video.classList.add(`wistia_async_${data.wistiaId}`)
  $video.classList.add('WS')

  wistiaQueue.push({ id: data.wistiaId, onReady: handleVideoReady })
}

////////////////////////////////////////////////
// More Wistia config.

function getStillUrl(stillUrl: string): string | undefined {
  if (stillUrl === STOCK_WISTIA_THUMBNAIL) {
    return undefined
  }

  return stillUrl
}

function getWistiaConfiguration(): {
  email: string
  playerPreference: string
  playerColor: string
} {
  const $customColor = document.querySelector(PLAYER_COLOR_SELECTOR)
  let playerColor

  if ($customColor) {
    const customColor = $customColor.getAttribute('content')

    playerColor = customColor ? customColor.replace(/\#/, '') : playerColor
  }

  const user = getCurrentUser()
  const email = user.id ? `${user.schoolId}:student:${user.id}` : 'guest'
  const playerPreference = getControlValue('playerPreference')

  return { email, playerColor, playerPreference }
}

////////////////////////////////////////////////
// Video functions.

function handleVideoReady(video) {
  noDownload(video)
  setHeight(video)
  track(video)

  const savedControls = getControls()
  const videoSpeed = get(savedControls, 'speed', 1)
  const id = video.container.id.replace(/wistia\-/, '')
  const play = () => video.play()
  const setTime = time => {
    video.time(time)
  }
  const getTime = () => video.time()
  const attachmentId = video.container.getAttribute('data-attachment-id')
  const duration = Math.floor(video.duration())

  video.playbackRate(videoSpeed)

  pub('video:ready', { attachmentId, duration, id, play, getTime, setTime })
}

function getEventKey() {
  return localStorage.getItem('__wistiaEventKey__')
}

function setEventKey(eventKey) {
  localStorage.setItem('__wistiaEventKey__', eventKey)
}

function postEventKey(video) {
  const event_key = video.eventKey()

  if (getEventKey() === event_key) {
    return
  }

  setEventKey(event_key)

  const visitor_key = Wistia.visitorKey.value()
  const host_id = video.hashedId()
  const lecture_id = currentLectureId()

  api('/api/v1/wistia_events', {
    method: 'POST',
    data: { event_key, host_id, lecture_id, visitor_key },
  })
}

function setHeight(video) {
  const width = video.container.clientWidth
  const aspect = video.aspect()

  const $responsivePadding = findAncestor(video.container, element =>
    element.classList.contains('wistia_responsive_padding')
  )

  const paddingBottom = (width / aspect / width) * MAX_PERCENTAGE
  $responsivePadding.style.paddingBottom = `${paddingBottom}%`
  $responsivePadding.style.height = '0px'
}

function track(video) {
  const id = video.container.id.replace(/wistia\-/, '')
  const attachmentId = video.container.getAttribute('data-attachment-id')

  video.bind('beforeremove', () => {
    return video.unbind
  })

  video.bind('play', () => {
    postEventKey(video)

    const duration = Math.floor(video.duration())
    const name = video.name()
    const play = () => video.play()

    pub('video:start', { attachmentId, id, duration, name, play })
  })

  video.bind('end', () => {
    const videos = getCurrentVideos()
    const next = videos.length > 0 && videos[videos.indexOf(id) + 1]
    const nextVideo = next ? Wistia.api(next) : null

    const playNext = () => {
      if (nextVideo) {
        scrollIntoView(nextVideo.container)
        nextVideo.play()
      }
    }

    pub('video:end', { attachmentId, id, name: video.name(), playNext })
  })

  video.bind('secondchange', () => {
    const percentWatched = video.percentWatched()
    const time = Math.floor(video.time())

    pub('video:progress', { attachmentId, id, percentWatched, time })
  })

  video.bind('pause', () => {
    const percentWatched = video.percentWatched()
    const time = Math.floor(video.time())

    pub('video:pause', { attachmentId, id, percentWatched, time })
  })
}

function noDownload({ container }) {
  const $video = container.querySelector('video')

  if ($video) {
    $video.setAttribute('controlsList', 'nodownload')
  }
}

onControlChange('speed', playbackRate => {
  Array.from(document.querySelectorAll(WISTIA_SELECTOR)).forEach($video => {
    const { wistiaId } = getDataAttributesAsObject($video)
    Wistia.api(wistiaId).playbackRate(playbackRate)
  })
})
