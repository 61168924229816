import { QuestionTypes, QuizProps, QuizState } from './types'

// Takes in a schema from the liquid and serializes it into a nicer
// format to use in this React app.
export function serializeQuizItems(questions: any) {
  return Object.values(questions).map(
    (current): QuestionTypes => {
      return {
        // Only using "any" where I'm interacting with old api implementations.
        // I don't want to invest the time typing them out since I'm not using
        // them in the rest of my app.
        choices: (current as any).enum,
        multiple: (current as any).description === 'multiple',
        title: (current as any).title,
      }
    }
  )
}

export function getInitialState(props: QuizProps): QuizState {
  const { answers, lectureCompletionData, grade } = props
  const state: QuizState = {
    answers: props.answers || [],
    canAdvance: false,
    currentQuestion: 0,
    fetchingData: false,
    grade: null,
    selectedChoices: [],
    lectureCompletionData: lectureCompletionData || null,
  }

  if (grade) {
    state.isComplete = grade.answered === grade.total

    if (!state.isComplete) {
      state.currentQuestion = grade.answered || 0
    }

    state.grade = {
      answered: grade.answered,
      correct: grade.correct,
    }
  }

  if (!state.isComplete && answers.length) {
    state.currentQuestion = answers.filter(({ user }) => !!user).length
  }

  return state
}
