interface IScrollTo {
  behavior?: 'smooth' | 'instant' | 'auto'
  left?: number
  top?: number
}

declare const scrollTo: (config: IScrollTo) => void

export function smoothScrollTo(top: number) {
  scrollTo({ behavior: 'smooth', top })
}

export function scrollIntoView($el: HTMLElement) {
  if ($el.scrollIntoView) {
    $el.scrollIntoView({ behavior: 'smooth', block: 'start' })
  } else {
    smoothScrollTo($el.offsetTop)
  }
}
