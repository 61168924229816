export const DAYS_UNTIL_DRIPPED_ATTR = 'data-days-until-dripped'
export const DRIPPED_BY_DATE_ATTR = 'data-is-dripped-by-date'
export const DRIP_SECTION_SELECTOR = `[${DAYS_UNTIL_DRIPPED_ATTR}]`
export const COURSE_MAINBAR_SELECTOR = '.course-mainbar'
export const COMPLETE_BUTTON_ID = 'lecture_complete_button'
export const DATA_META_ID = 'fedora-data'
export const DAYS_TO_DRIP_SELECTOR = '.section-days-to-drip-number'
export const DAYS_LOGGED_IN_SELECTOR = '.section-days-logged-in'
export const DAYS_LOGGED_OUT_SELECTOR = '.section-days-logged-out'
export const DRIP_SECTION_CLASS = 'drip-section'
export const COURSE_SECTION_LOCKED_CLASS = 'course-section-locked'
export const DRIP_LECTURE_LOCKED_CLASS = 'drip-lecture-locked'
export const LOCKED_DRIP_CLASS = 'locked-drip'
export const LOCK_ICON_CLASS = 'fa-lock'
export const CLOCK_ICON_CLASS = 'fa-clock-o'
