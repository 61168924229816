import { LOCAL_STORAGE_KEY } from './constants'

const $: any = (window as any).$

if (
  typeof localStorage !== 'undefined' &&
  location.search.indexOf('clearControlsCache') > -1
) {
  localStorage.removeItem(LOCAL_STORAGE_KEY)
}

function getLegacyControls(): Controls.Values {
  const controls: { [key: string]: any } = {}

  // Old-school jquery cookies are handled here.
  if (typeof $ !== 'undefined' && $.cookie) {
    const autocomplete = $.cookie('videoAutoComplete')
    if (autocomplete === true || autocomplete === 'true') {
      controls.autocomplete = true
    }

    const autoplay = $.cookie('videoAutoPlay')
    if (autoplay === true || autoplay === 'true') {
      controls.autoplay = true
    }

    const playerPreference = $.cookie('videoPlayer')
    if (playerPreference === 'html5' || playerPreference === 'flash') {
      controls.playerPreference = playerPreference
    }

    const speed = $.cookie('videospeed')
    if (speed) {
      try {
        controls.speed = parseInt(speed, 10)
      } catch (e) {
        // swallow the error.
      }
    }
  }

  return controls as Controls.Values
}

export function getControls(): Controls.Values {
  const json =
    typeof localStorage !== 'undefined'
      ? localStorage.getItem(LOCAL_STORAGE_KEY)
      : null

  if (!json) {
    // Check for the old-school jquery cookie data. This will
    // return an empty object if no data is found.
    return getLegacyControls()
  }

  let controls: Controls.Values

  try {
    controls = JSON.parse(json)
  } catch (e) {
    return {}
  }

  return controls as Controls.Values
}

export function persistControls(data) {
  if (typeof localStorage === 'undefined') {
    return
  }

  try {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data))
  } catch (e) {
    throw new Error(e)
  }
}
