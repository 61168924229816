/**
 * @file
 * A generic pub sub system. Usually you'd use each function in different
 * files, but the example below shows them both in the same file.
 *
 * @example
 * import { pub, sub } from
 *
 * const subscriptions = sub('my-event', () => console.log('executing'))
 *
 * setTimeout(() => {
 *   pub('my-event')
 * }, 500)
 *
 * setTimeout(() => {
 *   subscription.unsub()
 * }, 1000)
 */

import { get } from 'lodash'
import hash from 'src/util/hash'

const REGISTERED_EVENTS = {
  'toggle.sidebar': 1,
  'new-text-attachment-added': 1,
  'new-code-attachment-added': 1,
  'custom-role:created': 1,
  'custom-role:updated': 1,
  'custom-role:destroyed': 1,
  'modal:close': 1,
  'modal:open': 1,
  'modal:update': 1,
  '_route:change_': 1,
  '404search': 1,
  '500search': 1,
  'growl:addsuccessmessage': 1,
  'growl:adderrormessage': 1,
  'control:change': 1,
  'video:end': 1,
  'video:pause': 1,
  'video:start': 1,
  'video:progress': 1,
  'video:ready': 1,
  routeChange: 1,
  'user-filters:updated': 1,
}

const APP_CONFIG = (window as any).APP_CONFIG
const INSTRUCTIONS =
  'Please add your event to manifest.ts and remember to use pubsub sparingly.'

const subs = {}
const map = {}

const generateSubHash = (name: string, fn: Function) =>
  hash(`${name}/${fn.toString()}`)
const getPosition = sig => map[sig]

function getName(name) {
  if (!REGISTERED_EVENTS[name]) {
    return undefined
  }

  return encodeURIComponent(name)
}

function log(msg) {
  const env = get(APP_CONFIG || {}, 'config.keys.environment')

  if (env !== 'development') {
    return
  }

  console.error(
    `%c [pubsub.ts]: %c${msg} ${INSTRUCTIONS}`,
    'font-weight: bold;',
    'font-weight: normal;'
  )
}

export const pub = (unencodedName: string, payload?: any) => {
  const name = getName(unencodedName)

  if (!name) {
    log(`Cannot publish "${unencodedName}". Please add it to the manifest.`)

    return
  }

  if (!subs[name] || !subs[name].length) {
    return
  }

  subs[name].forEach(fn => fn(payload))
}

export const sub = (unencodedName, fn: Function) => {
  const name = getName(unencodedName)

  if (!name) {
    log(
      `Cannot subscribe to "${unencodedName}". Please add it to the manifest.`
    )

    return undefined
  }

  if (!subs[name]) {
    subs[name] = []
  }

  // Cache the callback.
  subs[name].push(fn)

  const hash = generateSubHash(name, fn)

  // And cache its location in the subs object.
  map[hash] = [name, subs[name].length - 1]

  return {
    // a convenience method for unsubscribing to an event because
    // sometimes the removeEventListener way is a bummer.
    unsub: () => unsub(name, fn),
  }
}

// Works like removeEventListener, pass it identical arguments to the
// sub function and it'll remove the subscription.
export const unsub = (name: string, fn: Function) => {
  const hash = generateSubHash(name, fn)
  const pos = getPosition(hash)

  if (!pos) {
    return
  }

  const index = pos[1]

  const arr = [...subs[name]]
  arr.splice(index, 1)

  subs[name] = arr

  delete map[hash]
}

export const unsubAll = (unencodedName: string) => {
  const name = getName(unencodedName)

  subs[name] = null
}
