import classNames from 'classnames'
import { get } from 'lodash'
import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { COMPLETE_BUTTON_ID, getLockedCompleteButtonTooltipText } from '..'
import { unlockPreviousAndCompleteButtons } from '../'
import { tooltip } from '../../components/tooltip'
import { checkAnswer } from './api'
import { Complete } from './Complete'
import { Controls } from './Controls'
import { Question } from './Question'
import './Quiz.scss'
import { QuizProps, QuizState } from './types'
import { getInitialState } from './util'

// Matches the SCSS constant of the same name in app/assets/stylesheets/modules/quiz.scss.
const QUIZ_TRANSITION_TIME = 300

export class Quiz extends React.Component<QuizProps, QuizState> {
  readonly state: QuizState = getInitialState(this.props)
  complianceTooltip: any

  componentDidUpdate() {
    // When the lecture completion data updates, check to see if
    // a tooltip needs to be added
    const { isComplete, lectureCompletionData } = this.state
    const showComplianceTooltip =
      get(
        lectureCompletionData,
        'compliance_requirements.quiz_completion_requirements_met'
      ) === false && !isComplete

    if (!this.complianceTooltip && showComplianceTooltip) {
      this.insertComplianceTooltip()
    }
  }

  goToQuestionAtIndex(index: number) {
    const { answers } = this.state
    const newState = {
      canAdvance: false,
      currentQuestion: index,
    }

    if (answers[index] && answers[index].correct) {
      newState.canAdvance = true
    }

    this.setState(newState)
  }

  goToNext = () => {
    const { currentQuestion } = this.state
    const { questions } = this.props

    if (currentQuestion === questions.length - 1) {
      this.setState({ isComplete: true })
      return
    }

    this.goToQuestionAtIndex(this.state.currentQuestion + 1)
  }

  goToPrevious = () => {
    const { currentQuestion } = this.state

    if (currentQuestion === 0) {
      return
    }

    this.goToQuestionAtIndex(currentQuestion - 1)
  }

  resetQuizData = () => {
    this.setState({
      canAdvance: false,
      answers: [],
      currentQuestion: 0,
      isComplete: false,
    })
  }

  selectChoice = (index: number) => {
    const { answers, canAdvance, currentQuestion } = this.state

    if (canAdvance) {
      return
    }

    const { questions } = this.props
    const { choices } = questions[currentQuestion]
    const selectedChoices = answers[currentQuestion] || {}
    const result = [...answers]

    if (
      selectedChoices.user &&
      selectedChoices.user.indexOf(choices[index]) > -1
    ) {
      const user =
        selectedChoices.user.length > 1
          ? selectedChoices.user.filter(choice => choice !== choices[index])
          : []

      result.splice(currentQuestion, 1, { user })
    } else {
      const question = questions[currentQuestion]

      // If multiple answers, let them select multiple choices.
      // Otherwise overwrite the choice.
      let choices = [question.choices[index]]
      if (question.multiple && selectedChoices && selectedChoices.user) {
        choices = [...selectedChoices.user, ...choices]
      }

      result.splice(currentQuestion, 1, { user: choices })
    }

    this.setState({ answers: result })
  }

  setHeight = ($el: HTMLElement) => {
    const { height } = this.state

    if (!$el || (height && height === $el.clientHeight)) {
      return
    }

    this.setState({ height: $el.clientHeight })
  }

  submitAnswer = async () => {
    const { fetchingData } = this.state

    if (!fetchingData) {
      const { id } = this.props
      const { answers, currentQuestion } = this.state
      const currentAnswer = answers[currentQuestion]

      if (!currentAnswer || !currentAnswer.user || !currentAnswer.user.length) {
        return
      }

      this.setState({ fetchingData: true })

      const {
        currentCorrectResponse,
        grade,
        lectureCompletionData,
      } = await checkAnswer({ answers, id, indexQuestion: currentQuestion })
      const result = [...answers]

      result.splice(currentQuestion, 1, {
        correct: currentCorrectResponse,
        user: currentAnswer.user,
      })

      this.setState({ fetchingData: false })
      this.onResponseSubmitted(result, grade, lectureCompletionData)
    }
  }

  onResponseSubmitted = (result, grade, lectureCompletionData) => {
    this.setState({
      answers: result,
      canAdvance: true,
      grade,
      lectureCompletionData,
    })

    if (get(lectureCompletionData, 'valid_for_completion')) {
      unlockPreviousAndCompleteButtons()
    }
  }

  canRetake = () => {
    const { isGraded } = this.props
    const { lectureCompletionData } = this.state

    return (
      !isGraded ||
      get(
        lectureCompletionData,
        'compliance_requirements.remaining_quiz_retakes'
      ) > 0
    )
  }

  insertComplianceTooltip = () => {
    const completeButton = document.getElementById(COMPLETE_BUTTON_ID)
    this.complianceTooltip = tooltip(
      getLockedCompleteButtonTooltipText(),
      completeButton,
      'locked-complete-button'
    )
  }

  render() {
    const {
      answers,
      canAdvance,
      currentQuestion,
      grade,
      height,
      fetchingData,
      isComplete,
      lectureCompletionData,
    } = this.state
    const { id, questions, strings } = this.props
    const currentAnswer = answers[currentQuestion] || {}
    const wrapperClass = classNames('quiz Quiz', {
      answered: canAdvance,
      finished: isComplete,
      multiple: questions[currentQuestion].multiple,
      single: !questions[currentQuestion].multiple,
    })

    return (
      <div className="quiz-content" aria-live="polite">
        <div id="quiz-app">
          <div className="quiz-wrapper attachment-block-wrapper">
            <div className="attachment-block-label">{strings.label}</div>
            <div className={wrapperClass}>
              {isComplete && (
                <Complete
                  actions={{ reset: this.resetQuizData }}
                  canRetake={this.canRetake()}
                  grade={grade}
                  id={id}
                  lectureCompletionData={lectureCompletionData}
                  strings={{
                    retake: strings.retake,
                    scored: strings.scored,
                  }}
                />
              )}
              {!isComplete && (
                <React.Fragment>
                  <div
                    className="quiz-progress"
                    aria-label={`Question ${currentQuestion + 1} out of ${
                      questions.length
                    }`}
                  >
                    {`${currentQuestion + 1} / ${questions.length}`}
                  </div>
                  <div className="quiz-question-outer" style={{ height }}>
                    <TransitionGroup>
                      <CSSTransition
                        classNames="Quiz-item"
                        key={`question-${currentQuestion}`}
                        timeout={QUIZ_TRANSITION_TIME}
                      >
                        <Question
                          actions={{
                            selectChoice: this.selectChoice,
                            setHeight: this.setHeight,
                            submitAnswer: this.submitAnswer,
                          }}
                          currentCorrectResponse={currentAnswer.correct}
                          question={questions[currentQuestion]}
                          selectedChoices={currentAnswer.user || []}
                          strings={strings}
                        />
                      </CSSTransition>
                    </TransitionGroup>
                  </div>
                  <Controls
                    actions={{
                      goToNext: this.goToNext,
                      goToPrevious: this.goToPrevious,
                      submitAnswer: this.submitAnswer,
                    }}
                    canAdvance={canAdvance}
                    canCheck={Boolean(
                      currentAnswer.user &&
                        currentAnswer.user.length &&
                        !currentAnswer.correct
                    )}
                    canRecede={currentQuestion > 0}
                    id={id}
                    isChecking={fetchingData}
                    strings={{
                      check: strings.check,
                      next: strings.next,
                      previous: strings.previous,
                    }}
                  />
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
