import { get } from 'lodash'
import React from 'react'
import { getCurrentUser } from 'student/shared/util/user'
import { emitStudentSideEvent } from '../../util/analytics-util'
import { CompleteProps } from './types'

export const Complete = ({
  actions,
  canRetake,
  grade,
  id,
  lectureCompletionData,
  strings,
}: CompleteProps) => {
  const MAX_PERCENTAGE = 100
  const compReqs = get(lectureCompletionData, 'compliance_requirements')
  const retakes = get(compReqs, 'remaining_quiz_retakes')
  const success =
    typeof get(compReqs, 'quiz_completion_requirements_met') === 'undefined' ||
    get(compReqs, 'quiz_completion_requirements_met')
  const minimumQuizScore = get(compReqs, 'minimum_quiz_score')
  const correct = Number(get(grade, 'correct', 0))
  const answered = Number(get(grade, 'answered', 0))
  const gradePerc = grade && Math.floor((correct / answered) * MAX_PERCENTAGE)

  const trackResetClick = (element: HTMLElement): void => {
    emitStudentSideEvent(element)
    actions.reset()
  }

  const user = getCurrentUser()

  return (
    <div className="quiz-finished Quiz-complete">
      {success && <i className="fa fa-check success" />}
      {!success && <i className="fa fa-times failure" />}
      {grade && (
        <div className="quiz-finished-text">
          {strings.scored} {grade.correct} / {grade.answered} ({gradePerc}%)
        </div>
      )}
      {!success && canRetake && retakes && (
        <div className="quiz-finished-message">
          You need a score of at least {minimumQuizScore}% to continue — you may
          retake this quiz {retakes} more time{retakes > 1 ? 's' : ''}.
        </div>
      )}
      {!success && !canRetake && (
        <div className="quiz-finished-message">
          You need a score of at least {minimumQuizScore}% to continue — a
          request has been sent to the school owner to reset your score. You
          will be notified if the school owner allows you to retake this quiz.
        </div>
      )}
      {canRetake && (
        <button
          className="btn btn-primary ng-scope"
          data-ss-event-name="Quiz: Retake"
          data-ss-event-type="button"
          data-ss-quiz-id={id}
          data-ss-school-id={user.schoolId}
          data-ss-user-id={user.id}
          onClick={e => trackResetClick(e.currentTarget)}
        >
          {strings.retake}
        </button>
      )}
    </div>
  )
}
