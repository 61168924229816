import api from 'student/shared/util/api'
import { VideoProgressDataType } from '../types'
import { getSchoolPreference } from './preferences'

import {
  MAX_PERCENTAGE,
  PERCENT_100,
  PERCENT_25,
  PERCENT_50,
  PERCENT_75,
} from '../constants'

const trackEvent = (window as any).trackEvent
const eventsSet = new Set()
const eventsPercentages = [PERCENT_25, PERCENT_50, PERCENT_75, PERCENT_100]

export const sendAnalyticsEvent = (id, percentWatched, data, lectureId) => {
  if (getSchoolPreference('disable_js_course_event_tracking')) {
    return
  }

  eventsPercentages.forEach(current => {
    const eventHash = `${id}:${current}`

    if (eventsSet.has(eventHash)) {
      return
    }

    if (percentWatched >= current) {
      eventsSet.add(eventHash)

      trackEvent(`Curriculum: Video Progress - ${current * MAX_PERCENTAGE}%`, {
        lectureId,
        ...data,
      })
    }
  })
}

export async function postProgress(
  courseId: string,
  lectureId: string,
  data: VideoProgressDataType
) {
  return await api(
    `/courses/${courseId}/lectures/${lectureId}/attachment_completions`,
    {
      data,
      method: 'POST',
    }
  )
}
