import api from 'student/shared/util/api'
import { LectureCompletionDataType } from '../../types'
import { AnswerType, GradeType, ResponseTypes } from './types'

interface CheckAnswerParams {
  answers: AnswerType[]
  id: number
  indexQuestion: number
}

interface SerializeResponseParams {
  correct: AnswerType
  grade: GradeType
  index: number
  lectureCompletionData: LectureCompletionDataType
}

const API_QUIZ_RESPONSES = '/responses'

function getAnswer(correct: AnswerType, index: number) {
  const key = `answer-${index + 1}`

  if (!correct || !correct[key]) {
    return null
  }

  return correct[key]
}

export async function checkAnswer({
  answers,
  id,
  indexQuestion,
}: CheckAnswerParams) {
  const userChoices = {}

  answers.forEach(({ user }, index) => {
    userChoices[`answer-${index + 1}`] = user
  })

  userChoices[`answer-${answers.length}`] = answers[indexQuestion].user

  const { correct, grade, lecture_completion_data } = await api(
    API_QUIZ_RESPONSES,
    {
      data: {
        answers: userChoices,
        topic_id: id,
        topic_type: 'Attachment',
      },
      method: 'POST',
    }
  )

  return serializeResponse({
    correct,
    grade,
    index: indexQuestion,
    lectureCompletionData: lecture_completion_data,
  })
}

function serializeResponse({
  correct,
  grade,
  index,
  lectureCompletionData,
}: SerializeResponseParams) {
  return new Promise<ResponseTypes>((resolve, reject) => {
    const correctList = getAnswer(correct, index)

    if (!correctList) {
      reject(new Error('Something went wrong, please try again.'))
      return
    }

    const currentCorrectResponse = {}

    correctList.forEach(correct => (currentCorrectResponse[correct] = true))

    resolve({
      currentCorrectResponse,
      grade: {
        answered: grade.answered,
        correct: grade.correct,
      },
      lectureCompletionData,
    })
  })
}
