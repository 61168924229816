/**

      _=====_                               _=====_
     / _____ \                             / _____ \
   +.-'_____'-.---------------------------.-'_____'-.+
  /   |     |       T E A C H A B L E   .'  |  _  |   \
 / ___| /|\ |___ \                     / ___| /_\ |___ \
/ |      |      | ;  __           _   ; | _         _ | ;
| | <---   ---> | | |__|         |_:> | ||_|       (_)| |
| |___   |   ___| ;                   ; |___       ___| ;
|\    | \|/ |    /  _     ___      _   \    | (X) |    /|
| \   |_____|  .','" "', |___|  ,'" "', '.  |_____|  .' |
|  '-.______.-' /       \      /       \  '-._____.-'   |
|               |       |------|       |                |
|              /\       /      \       /\               |
|             /  '.___.'        '.___.'  \              |
|            /                            \             |
 \          /                              \           /
  \________/                                \_________/

The student-side control panel. This is where let students set
their preferences on things like autoplaying videos and
autocompleting lectures.

*/

import { pub, sub } from 'src/util/pubsub'
import onReady from 'student/shared/util/on-ready'
import { NAMES, SPEED_INCREMENTER, SPEED_MAX, SPEED_MIN } from './constants'
import { getControls, persistControls } from './storage'

// A singleton for storing control values.
const cache = getControls()

// Control configuration. To add a new one, just add it here and create
// the onReady callback that takes an element and a name.
const CONTROLS = [
  {
    name: NAMES.AUTOPLAY,
    onReady: handleToggle,
    selector: '#custom-toggle-autoplay',
  },

  {
    name: NAMES.AUTOCOMPLETE,
    onReady: handleToggle,
    selector: '#custom-toggle-autocomplete',
  },

  {
    name: NAMES.SPEED,
    onReady: handleSpeed,
    selector: '.playback-speed',
  },

  {
    name: NAMES.FLASH,
    onReady: handlePlayerPreference,
    selector: '#toggle_flash',
  },

  {
    name: NAMES.HTML5,
    onReady: handlePlayerPreference,
    selector: '#toggle_html5',
  },
]

////////////////////////////////////////////////////////
// PUBLIC FUNCTIONS

export function onControlChange(which, callback) {
  sub('control:change', ({ name, value }) => {
    if (which === name) {
      callback(value)
    }
  })
}

export function getControlValue(control) {
  // Prioritize the cache first, since it's the student setting.
  if (cache && cache.hasOwnProperty(control)) {
    return cache[control]
  }

  // After that, prioritize the school setting.

  if (
    control === 'autocomplete' &&
    getSchoolPreference('autocomplete_lectures')
  ) {
    return true
  }

  if (control === 'autoplay' && getSchoolPreference('autoplay_lectures')) {
    return true
  }

  if (control === 'speed') {
    return 1
  }

  // Defaults to false.
  return false
}

////////////////////////////////////////////////////////
// CONTROL INITIALIZERS

declare const school_data: any
function getSchoolPreference(name) {
  const pref = school_data.schoolPreferences[name]

  return pref && pref === 'true'
}

function handlePlayerPreference($el, name) {
  const handleChange = () => save('playerPreference', name)

  $el.checked = getControlValue(NAMES.PLAYER_PREFERENCE) === name

  $el.addEventListener('change', handleChange)
}

function handleSpeed($el) {
  const $icon = document.createElement('span')
  $icon.className = 'glyphicon glyphicon-forward'
  $icon.innerHTML = `${getControlValue('speed')}x`
  $el.appendChild($icon)

  onControlChange('speed', speed => {
    $icon.innerHTML = `${speed}x`
  })

  const handleClick = () => {
    const currentSpeed = cache[NAMES.SPEED] || 1

    if (currentSpeed === SPEED_MAX) {
      save('speed', SPEED_MIN)
      return
    }

    save('speed', currentSpeed + SPEED_INCREMENTER)
  }

  $el.addEventListener('click', handleClick)
}

function handleToggle($el, name) {
  const handleChange = () => {
    save(name, $el.checked)
  }

  $el.checked = getControlValue(name)

  $el.addEventListener('change', handleChange)
}

////////////////////////////////////////////////////////
// UTIL FUNCTIONS

function save(name, value) {
  if (cache) {
    cache[name] = value
  }

  persistControls(cache)

  pub(`control:change`, { name, value })
}

////////////////////////////////////////////////////////
// INIT

onReady(() => {
  CONTROLS.forEach(control => {
    const $el = document.querySelector(control.selector)

    if ($el && !$el.getAttribute('data-control-initialized')) {
      $el.setAttribute('data-control-initialized', 'true')

      control.onReady($el, control.name)
    }
  })
})
