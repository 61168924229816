/**
 * Takes in a string and returns a hash.
 *
 * @param   {String} str
 * @returns {Number}
 */
export default (str: string) => {
  let hash = 0

  if (str.length === 0) {
    return hash
  }

  for (let i = 0; i < str.length; i += 1) {
    const char = str.charCodeAt(i)

    hash = (hash << 5) - hash + char

    hash |= 0 // Convert to 32bit integer
  }

  return hash
}
