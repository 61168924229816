import onReady from './util/on-ready'

const ATTR = 'data-imageloader'
const ATTR_CURRENT_SIZE = `${ATTR}-current-size`
const ATTR_SRC = `${ATTR}-src`

const ERROR_IMAGE = '[imageloader]: The first argument must be an HTMLElement.'
const ERROR_PARENT = '[imageloader]: Parent has no width.'
const ERROR_URL = '[imageloader]: Could not find image URL.'

const FILEPICKER_BASE_URL = 'https://process.fs.teachablecdn.com/ADNupMnWyR7kCWRvm76Laz'

const SIZES = [250, 500, 750, 1000, 1500, 2500]

const getImageUrl = ($image) => $image.getAttribute(ATTR_SRC)
const getCurrentSize = ($image) => parseInt($image.getAttribute(ATTR_CURRENT_SIZE), 10)
const getDevicePixelRatio = () => devicePixelRatio || 1
const getFilepickerBase = (width) => `${FILEPICKER_BASE_URL}/resize=width:${width}`
const getImageSize = (width) => SIZES.find((size) => size >= width) || SIZES[SIZES.length - 1]
const getMinWidth = (width) => width * getDevicePixelRatio()
const getResponsiveUrl = (url, width) => `${getFilepickerBase(width)}/${url}`

// Automatically load all images on page load (and on turbolinks page:ready).
onReady(loadAllImages)

/**
 * Handles all the DOM stuff on the image.
 *
 * @private
 * @param   {HTMLElement} $image
 * @param   {Object}      data
 */
function setImageAttributes($image, data) {
  $image.setAttribute('src', data.url)
  $image.setAttribute(ATTR_CURRENT_SIZE, (data.size || ''))

  if ($image.className.indexOf('is-loaded') === -1) {
    const handleLoad = () => $image.classList.add('is-loaded')

    $image.addEventListener('load', handleLoad, { once: true })
  }
}

function getAncestorWithConditions($el, conditionResolver) {
  let result

  const checkConditions = ($current) => {
    if (conditionResolver($current)) {
      result = $current
    }

    if (!result && $current.parentElement) {
      checkConditions($current.parentElement)
    }
  }

  checkConditions($el)

  return result
}

/**
 * Loads an image based on the size of the parent element.
 *
 * @param   {HTMLElement} $image  The image element.
 * @returns {Promise}
 */
export function loadImage($image) {
  return new Promise((resolve, reject) => {
    if (!($image instanceof HTMLElement)) {
      return reject(ERROR_IMAGE)
    }

    const baseUrl = getImageUrl($image)
    if (!baseUrl) {
      return reject(ERROR_URL)
    }

    if ($image.getAttribute('data-imageloader-skip-resize') === 'true') {
      setImageAttributes($image, { url: baseUrl })
      return resolve({ size: '' })
    }

    const $ancestor = getAncestorWithConditions($image.parentElement, ($el) => $el.offsetWidth)
    const ancestorWidth = $ancestor ? $ancestor.offsetWidth : undefined
    if (!ancestorWidth) {
      return reject(ERROR_PARENT)
    }

    const currentSize = getCurrentSize($image)
    const size = getImageSize(getMinWidth(ancestorWidth))
    if (currentSize && size <= currentSize) {
      // Don't download the same image twice. Also don't download a smaller
      // image if we've already got a larger one.
      return resolve({ size: currentSize })
    }

    setImageAttributes($image, { size, url: getResponsiveUrl(baseUrl, size) })

    return resolve({ size })
  })
}

/**
 * Loads all image on a page.
 *
 * @returns {Promise}
 */
export function loadAllImages() {
  const $images = document.querySelectorAll(`img[${ATTR}]`)
  const imagePromises = Array.prototype.map.call($images, loadImage)

  return Promise.all(imagePromises)
}
