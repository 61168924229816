/**
 * @file
 * This file handles the embedding of PDFs on the student-side.
 * There are a few oddities to note in here:
 *
 *  1. We're hard-coding the API key for Filepicker, see the
 *     comment below.
 *
 *  2. The CSS URL in here is just a random file hosten on our
 *     filestack account. It's a bit fragile to have this random
 *     URL referenced in here but it has to be done to hide the
 *     download and print buttons in the PDF viewer.
 *
 *  3. We're using the NPM version of filestack, which differs
 *     from the one in the rest of our stack.
 *
 * @example
 * <div
 *   data-pdfviewer
 *   data-pdfviewer-filepicker-id="{{ attachment.filepicker_id }}"
 * ></div>
 */

import filestack from 'filestack-js'
import onReady from './util/on-ready'

const DATA_ATTR = 'data-pdfviewer'
const css = 'https://assets.teachablecdn.com/css/filestack-pdf-viewer.css'

function init() {
  const $fedoraKeys = document.getElementById('fedora-keys')
  if (!$fedoraKeys) {
    return
  }

  const filepickerAPIKey = $fedoraKeys.getAttribute('data-filepicker')
  if (!filepickerAPIKey) {
    return
  }

  const filestackInstance = filestack.init(filepickerAPIKey)
  const $els = document.querySelectorAll(`[${DATA_ATTR}]`)

  Array.prototype.forEach.call($els, ($el) => {
    const filepickerId = $el.getAttribute(`${DATA_ATTR}-id`)
    const { id } = $el

    filestackInstance.preview(filepickerId, { css, id })
  })
}

onReady(init)

