import localforage from 'localforage'
import { VideoCacheType } from '../types'

const LOCAL_STORAGE_KEY = '__PROGRESS_VIDEO__'

if (location.search.indexOf('clearVideoProgress=1') > -1) {
  clearStorage()
}

export function clearStorage() {
  localforage.removeItem(LOCAL_STORAGE_KEY)
}

export function saveToStorage(cache: VideoCacheType) {
  localforage.setItem(LOCAL_STORAGE_KEY, cache)
}

export async function getPersistentData(): Promise<VideoCacheType | {}> {
  return localforage.getItem(LOCAL_STORAGE_KEY)
}
