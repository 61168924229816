let cachedUser

interface User {
  hellosignId: string | number // I'm not sure which.
  lectureUrl: string
  lectureId: number
  vatCountry: string
  courseUrl: string
  courseId: number
  schoolPreferences: {
    [key: string]: boolean
  }
  assetPath: string
  poweredByText: string
  planSupportsWhitelabeling: boolean
  turnOffFedoraBranding: boolean
  preview: string
  schoolLaunched: boolean
  completedLectureIds: Number[]
  lastFour: number
  role: string
  courseIds: Number[]
  reportCard: any
  gravatarUrl: string
  wistiaIdentifier: string
  joinedAt: string
  src: null
  schoolId: number
  email: string
  name: string
  id: number
}

declare const currentUser: any

// All of the booleans on the user object used to be 'true' or 'false'
// as strings. This function recursively loops through the user object
// converting those strings to actual booleans.
function sanitizeUser(user: any): User {
  const result = {}

  function loopThroughObject(current, target) {
    Object.keys(current).forEach(key => {
      if (current[key] === 'true') {
        target[key] = true
      } else if (current[key] === 'false') {
        target[key] = false
      } else if (current[key] instanceof Object) {
        target[key] = {}
        loopThroughObject(current[key], target[key])
      } else {
        target[key] = current[key]
      }
    })
  }

  loopThroughObject(user, result)

  return result as User
}

export function getCurrentUser(): User {
  const user = currentUser()

  if (cachedUser) {
    return cachedUser
  }

  cachedUser = sanitizeUser(user)

  return cachedUser
}
