import { LockedDripLecture } from './LockedDripLecture'

export function initDrip() {
  document.addEventListener('lecture:ajax_success', () => {
    new LockedDripLecture()
  })

  setTimeout(() => {
    new LockedDripLecture()
  })
}
